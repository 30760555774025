import React, { FC } from "react";
import { graphql } from "gatsby";

import { AboutPageQuery } from "lib/cms";

import ContentBlocks from "components/ContentBlocks";
import PageWrapper from "components/PageWrapper";

import NotFoundPage from "./404";
import MetaData from "components/MetaData";

interface Props {
  data: AboutPageQuery;
  location: Location;
}

const AboutPage: FC<Props> = ({ data: { page } }) => {
  return page ? (
    <PageWrapper backgroundColor={page.backgroundColor}>
      <MetaData title="About" />
      <ContentBlocks blocks={page.content} smallText />
    </PageWrapper>
  ) : (
    <NotFoundPage />
  );
};

export const query = graphql`
  query AboutPage {
    page: sanityAbout {
      backgroundColor
      content: content {
        _key
        backgroundColor
        content: _rawContent
      }
    }
  }
`;

export default AboutPage;
